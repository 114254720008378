//Variables del tema

//Color
$color: #e5e6e9;
$color-fondo: #5b7c99;
$color-fondo-invert: #036287;
$color-fuente-invert: #004b8b;

//Fuente
$fuente:Helvetica Neue, Helvetica, Arial;



//Bordes redondeados
@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  border-radius: $radius;
}

//Fondos con gradiente radial
@mixin gradient-radial($color-base, $color-medio, $color-final) {
  background: $color-base;
  background: -moz-radial-gradient(center, ellipse cover, $color-base 0%, $color-medio 0%, $color-final 100%);
  background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%, $color-base), color-stop(0%, $color-medio), color-stop(100%, $color-final));
  background: -webkit-radial-gradient(center, ellipse cover, $color-base 0%, $color-medio 0%, $color-final 100%);
  background: -o-radial-gradient(center, ellipse cover, $color-base 0%, $color-medio 0%, $color-final 100%);
  background: -ms-radial-gradient(center, ellipse cover, $color-base 0%, $color-medio 0%, $color-final 100%);
  background: radial-gradient(ellipse at center, $color-base 0%, $color-medio 0%, $color-final 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#004b8b', endColorstr='#b7deed', GradientType=1 );
}

//Fondos con gradiente vertical
@mixin gradient-vertical($color-base, $color-medio, $color-final, $fondo) {
  background: $fondo;
  background: -moz-linear-gradient(top, $color-base 0%,  53%, $color-final 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, $fondo), color-stop(53%, $color-medio), color-stop(100%, $color-final));
  background: -webkit-linear-gradient(top, $fondo 0%, $color-medio 53%, $color-final 100%);
  background: -o-linear-gradient(top, $fondo 0%, $color-medio 53%, $color-final 100%);
  background: -ms-linear-gradient(top, $fondo 0%, $color-medio 53%, $color-final 100%);
  background: linear-gradient(to bottom, $fondo 0%, $color-medio 53%, $color-final 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#27b3eb', endColorstr='#178bb5', GradientType=0);
}

//Gradiente fijo
@mixin gradient-fixed() {
  background: rgba(255, 255, 255, 1);
  background: -moz-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(216, 228, 240, 1) 28%, rgba(190, 212, 235, 1) 56%, rgba(190, 212, 235, 1) 79%, rgba(190, 212, 235, 1) 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(255, 255, 255, 1)), color-stop(28%, rgba(216, 228, 240, 1)), color-stop(56%, rgba(190, 212, 235, 1)), color-stop(79%, rgba(190, 212, 235, 1)), color-stop(100%, rgba(190, 212, 235, 1)));
  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(216, 228, 240, 1) 28%, rgba(190, 212, 235, 1) 56%, rgba(190, 212, 235, 1) 79%, rgba(190, 212, 235, 1) 100%);
  background: -o-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(216, 228, 240, 1) 28%, rgba(190, 212, 235, 1) 56%, rgba(190, 212, 235, 1) 79%, rgba(190, 212, 235, 1) 100%);
  background: -ms-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(216, 228, 240, 1) 28%, rgba(190, 212, 235, 1) 56%, rgba(190, 212, 235, 1) 79%, rgba(190, 212, 235, 1) 100%);
  background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(216, 228, 240, 1) 28%, rgba(190, 212, 235, 1) 56%, rgba(190, 212, 235, 1) 79%, rgba(190, 212, 235, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#bed4eb', GradientType=0);
}

//Footer
$height-footer: 100px;

//Sombra
@mixin sombra($left, $top, $right, $bottom, $color) {
  -webkit-box-shadow: $left $top $right $bottom $color;
  -moz-box-shadow: $left $top $right $bottom $color;
  box-shadow: $left $top $right $bottom $color;
}