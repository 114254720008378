/*!
 * jquery-confirm v1.7.5 (http://craftpip.github.io/jquery-confirm/)
 * Author: boniface pereira
 * Website: www.craftpip.com
 * Contact: hey@craftpip.com
 *
 * Copyright 2013-2015 jquery-confirm
 * Licensed under MIT (https://github.com/craftpip/jquery-confirm/blob/master/LICENSE)
 */
body.jconfirm-noscroll {
  overflow: hidden !important;
}
@-webkit-keyframes jconfirm-rotate {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes jconfirm-rotate {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.jconfirm {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999999;
  font-family: inherit;
  overflow: hidden;
}
.jconfirm .jconfirm-bg {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  -webkit-transition: all .4s;
          transition: all .4s;
}
.jconfirm .jconfirm-bg.seen {
  opacity: 1;
}
.jconfirm .jconfirm-scrollpane {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
}
.jconfirm .jconfirm-box {
  background: white;
  border-radius: 4px;
  position: relative;
}
.jconfirm .jconfirm-box div.closeIcon {
  height: 20px;
  width: 20px;
  position: absolute;
  top: 12px;
  right: 12px;
  cursor: pointer;
  opacity: .6;
  text-align: center;
  display: none;
}
.jconfirm .jconfirm-box div.closeIcon:hover {
  opacity: 1;
}
.jconfirm .jconfirm-box div.title {
  font-size: 24px;
  font-weight: bold;
  font-family: inherit;
  padding: 10px 15px 5px;
}
.jconfirm .jconfirm-box div.content {
  padding-top: 10px;
  padding: 10px 15px 10px;
}
.jconfirm .jconfirm-box div.content:empty {
  height: 40px;
  position: relative;
  opacity: 0.6;
}
.jconfirm .jconfirm-box div.content:empty:before {
  content: '';
  height: 20px;
  width: 20px;
  border: solid 2px #aaa;
  position: absolute;
  left: 50%;
  margin-left: -45px;
  border-radius: 20%;
  -webkit-animation: jconfirm-rotate 1s infinite;
          animation: jconfirm-rotate 1s infinite;
}
.jconfirm .jconfirm-box div.content:empty:after {
  content: 'loading..';
  position: absolute;
  left: 50%;
  margin-left: -15px;
}
.jconfirm .jconfirm-box .buttons {
  padding: 10px 15px;
}
.jconfirm .jconfirm-box .buttons button + button {
  margin-left: 5px;
}
.jconfirm .jquery-clear {
  clear: both;
}
.jconfirm.white .jconfirm-bg {
  background-color: rgba(0, 0, 0, 0.2);
}
.jconfirm.white .jconfirm-box {
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}
.jconfirm.white .jconfirm-box .buttons {
  float: right;
}
.jconfirm.white .jconfirm-box .buttons button {
  border: none;
  background-image: none;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  text-shadow: none;
  -webkit-transition: background .1s;
          transition: background .1s;
  color: white;
}
.jconfirm.white .jconfirm-box .buttons button.btn-default {
  box-shadow: none;
  color: #333;
}
.jconfirm.white .jconfirm-box .buttons button.btn-default:hover {
  background: #ddd;
}
.jconfirm.black .jconfirm-bg {
  background-color: rgba(0, 0, 0, 0.5);
}
.jconfirm.black .jconfirm-box {
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  background: #444;
  border-radius: 5px;
  color: white;
}
.jconfirm.black .jconfirm-box .buttons {
  float: right;
}
.jconfirm.black .jconfirm-box .buttons button {
  border: none;
  background-image: none;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  text-shadow: none;
  -webkit-transition: background .1s;
          transition: background .1s;
  color: white;
}
.jconfirm.black .jconfirm-box .buttons button.btn-default {
  box-shadow: none;
  color: #fff;
  background: none;
}
.jconfirm.black .jconfirm-box .buttons button.btn-default:hover {
  background: #666;
}
.jconfirm.hololight .jconfirm-bg {
  background-color: rgba(0, 0, 0, 0.5);
}
.jconfirm.hololight .jconfirm-box {
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.4);
  border-radius: 2px;
  overflow: hidden;
}
.jconfirm.hololight .jconfirm-box div.title {
  font-weight: inherit;
  border-bottom: solid 2px #76CFDF;
  color: #76CFDF;
}
.jconfirm.hololight .jconfirm-box .buttons {
  border-top: solid 2px #E7E7E7;
  width: 100%;
  float: none;
  padding: 0;
}
.jconfirm.hololight .jconfirm-box .buttons button {
  margin: 0;
  border: none;
  background: #fff;
  border-radius: 0px;
  width: 50%;
  padding: 13px;
  font-size: 16px;
  font-weight: bold;
  color: #666;
}
.jconfirm.hololight .jconfirm-box .buttons button + button {
  border-left: solid 2px #E7E7E7;
}
.jconfirm.holodark .jconfirm-bg {
  background-color: rgba(0, 0, 0, 0.5);
}
.jconfirm.holodark .jconfirm-box {
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.4);
  border-radius: 2px;
  background: #333;
  overflow: hidden;
}
.jconfirm.holodark .jconfirm-box div.closeIcon {
  color: white;
}
.jconfirm.holodark .jconfirm-box div.title {
  font-weight: inherit;
  border-bottom: solid 2px #76CFDF;
  color: #76CFDF;
}
.jconfirm.holodark .jconfirm-box div.content {
  color: white;
}
.jconfirm.holodark .jconfirm-box .buttons {
  border-top: solid 2px rgba(255, 255, 255, 0.2);
  width: 100%;
  float: none;
  padding: 0;
}
.jconfirm.holodark .jconfirm-box .buttons button {
  margin: 0;
  border: none;
  background: #333;
  border-radius: 0px;
  width: 50%;
  padding: 13px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  text-shadow: none;
}
.jconfirm.holodark .jconfirm-box .buttons button + button {
  border-left: solid 2px rgba(255, 255, 255, 0.2);
}
.jconfirm .jconfirm-box.hilight {
  box-shadow: 0 0 20px red;
}
/*Transition rules*/
.jconfirm {
  -webkit-perspective: 400px;
          perspective: 400px;
}
.jconfirm .jconfirm-box {
  opacity: 1;
  -webkit-transition-property: -webkit-transform, opacity, box-shadow;
          transition-property: transform, opacity, box-shadow;
}
.jconfirm .jconfirm-box.anim-top,
.jconfirm .jconfirm-box.anim-left,
.jconfirm .jconfirm-box.anim-right,
.jconfirm .jconfirm-box.anim-bottom,
.jconfirm .jconfirm-box.anim-opacity,
.jconfirm .jconfirm-box.anim-zoom,
.jconfirm .jconfirm-box.anim-scale,
.jconfirm .jconfirm-box.anim-none,
.jconfirm .jconfirm-box.anim-rotate,
.jconfirm .jconfirm-box.anim-rotatex,
.jconfirm .jconfirm-box.anim-rotatey,
.jconfirm .jconfirm-box.anim-scaley,
.jconfirm .jconfirm-box.anim-scalex {
  opacity: 0;
}
.jconfirm .jconfirm-box.anim-rotate {
  -webkit-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
          transform: rotate(90deg);
}
.jconfirm .jconfirm-box.anim-rotatex {
  -webkit-transform: rotateX(90deg);
          transform: rotateX(90deg);
  -webkit-transform-origin: center;
      -ms-transform-origin: center;
          transform-origin: center;
}
.jconfirm .jconfirm-box.anim-rotatey {
  -webkit-transform: rotatey(90deg);
      -ms-transform: rotatey(90deg);
          transform: rotatey(90deg);
  -webkit-transform-origin: center;
      -ms-transform-origin: center;
          transform-origin: center;
}
.jconfirm .jconfirm-box.anim-scaley {
  -webkit-transform: scaley(1.5);
      -ms-transform: scaley(1.5);
          transform: scaley(1.5);
  -webkit-transform-origin: center;
      -ms-transform-origin: center;
          transform-origin: center;
}
.jconfirm .jconfirm-box.anim-scalex {
  -webkit-transform: scalex(1.5);
      -ms-transform: scalex(1.5);
          transform: scalex(1.5);
  -webkit-transform-origin: center;
      -ms-transform-origin: center;
          transform-origin: center;
}
.jconfirm .jconfirm-box.anim-top {
  -webkit-transform: translate(0px, -100px);
      -ms-transform: translate(0px, -100px);
          transform: translate(0px, -100px);
}
.jconfirm .jconfirm-box.anim-left {
  -webkit-transform: translate(-100px, 0px);
      -ms-transform: translate(-100px, 0px);
          transform: translate(-100px, 0px);
}
.jconfirm .jconfirm-box.anim-right {
  -webkit-transform: translate(100px, 0px);
      -ms-transform: translate(100px, 0px);
          transform: translate(100px, 0px);
}
.jconfirm .jconfirm-box.anim-bottom {
  -webkit-transform: translate(0px, 100px);
      -ms-transform: translate(0px, 100px);
          transform: translate(0px, 100px);
}
.jconfirm .jconfirm-box.anim-zoom {
  -webkit-transform: scale(1.2);
      -ms-transform: scale(1.2);
          transform: scale(1.2);
}
.jconfirm .jconfirm-box.anim-scale {
  -webkit-transform: scale(0.8);
      -ms-transform: scale(0.8);
          transform: scale(0.8);
}
.jconfirm .jconfirm-box.anim-none {
  display: none;
}
.jconfirm.supervan .jconfirm-bg {
  background-color: rgba(54, 70, 93, 0.95);
}
.jconfirm.supervan .jconfirm-box {
  background-color: transparent;
}
.jconfirm.supervan .jconfirm-box div.closeIcon {
  color: white;
}
.jconfirm.supervan .jconfirm-box div.title {
  text-align: center;
  color: white;
  font-size: 28px;
  font-weight: normal;
}
.jconfirm.supervan .jconfirm-box div.content {
  text-align: center;
  color: white;
}
.jconfirm.supervan .jconfirm-box .buttons {
  text-align: center;
}
.jconfirm.supervan .jconfirm-box .buttons button {
  font-size: 16px;
  border-radius: 2px;
  background: #303f53;
  text-shadow: none;
  border: none;
  color: white;
  width: 25%;
  padding: 10px;
}
