/*!
 * @copyright Copyright &copy; Kartik Visweswaran, Krajee.com, 2013 - 2015
 * @version 1.4.3
 *
 * Styling for the dependent-dropdown JQuery plugin. 
 * 
 * For more JQuery plugins visit http://plugins.krajee.com
 * For more Yii related demos visit http://demos.krajee.com
 */
.kv-loading, .select2-container .kv-loading {
    background: url('../../images/loading.gif') right 20px center no-repeat;
    cursor: wait;
    opacity: 0.6;
}

.select2-container .kv-loading {
    background-position: right 0px center;
}