@import '../../../vendor/bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap';
@import '../../../vendor/bower_components/font-awesome-sass/assets/stylesheets/font-awesome';
@import '../../../vendor/bower_components/bootstrap-social/bootstrap-social';
@import '../../../vendor/bower_components/dependent-dropdown/css/dependent-dropdown';
@import '../../../vendor/bower_components/jquery-confirm2/css/jquery-confirm';
@import '../../../vendor/bower_components/select2/dist/css/select2';
@import '../../../vendor/bower_components/eonasdan-bootstrap-datetimepicker/src/sass/bootstrap-datetimepicker-build';
@import '../../../vendor/bower_components/bootstrap-fileinput/css/fileinput';
@import '../../../vendor/bower_components/perfect-scrollbar/css/perfect-scrollbar';
@import 'skin-lion/ui.fancytree.css';
@import url(https://fonts.googleapis.com/css?family=Ubuntu);

@import "partials/variables";

/*
    |--------------------------------------------------------------------------
    | Cases generales
    |--------------------------------------------------------------------------
    |
    | Clases que se pueden utilizar en cualquier página del sitio
    |
    */
html, body{
  height: 100%;
}
a:hover{
  text-decoration: none;
}
.col-centered{
  float: none !important;
  margin: 0 auto !important;
  text-align: center !important;
}
.title-style{
  color: #00539f;
  font-family: 'Ubuntu', sans-serif;
  font-size: 16px;
  font-weight: bold;
  padding-top: 15px;
}
.page-header{
  background-color: white;
  height: 120px;
  border: 0px;
  padding: 10px;
  padding-top: 0px;
  margin: 0px;
}
.wrap {
  min-height: 100%;
  height: auto !important;
  height: 100%;
  margin: 0 auto -100px;
}
.back{
  @include gradient-fixed();
}
.main-content{
  background-color: #ffffff;
  border-radius: 10px 10px 0px 0px;
  -moz-border-radius: 10px 10px 0px 0px;
  -webkit-border-radius: 10px 10px 0px 0px;
  box-shadow:inset 0px 0px 10px rgba(0,0,0,.2);
  -webkit-box-shadow:inset 0px 0px 10px rgba(0,0,0,.2);
  -moz-box-shadow:inset 0px 0px 10px rgba(0,0,0,.2);
  padding: 0px;
}
.menu-site {
  text-align: center;
  padding-right: 70px;
}
.menu-site ul{
  float: right;
}
.logo-site{
  padding: 15px 0px;
  text-align: right;
}
.logo-alfaomega{
  padding: 15px 0px;
}
.menu-site li {
  display: inline;
  list-style-type: none;
  font-family: 'Ubuntu', sans-serif;
  font-size: 14px;
  font-weight: bold;
}

@media (max-width: 400px) {
  .logo_alfaomega_tini {
    display: inline;
    text-align:center;
  }
  .logo_alfaomega_normal {
    display: none;
  }
  #tini_container{
    width: 35% !important;
  }
}

@media (min-width: 401px) {
  .logo_alfaomega_tini {
    display: none;
  }

  .logo_alfaomega_normal {
    display: inline;
  }
}

.menu-site div{
  color: #00549e;
  height: 70px;
  border-right: 1px solid #b6b6b6;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 55px;
  float: left;
  position: relative;
  cursor: pointer;
}
.menu-site div.lastone{
  border-right: 1px solid transparent;
}
.menu-site div:hover{
  border-right: 1px solid #000002;
  text-decoration: none;
}
.menu-site div.lastone:hover{
  border-right: 1px solid transparent;
}
.menu-site a {
  padding-bottom: 10px;
  border-bottom: 1px solid #b6b6b6;
}
.menu-site a:hover{
  color: #000002;
  border-bottom: 1px solid #000002;
  text-decoration: none;
}
.footer {
  width: 100%;
  height: 100px;
}
.footer .first-footer{
  height: 40px;
  background-color: #e7e8ea;
}
.footer .second-footer{
  height: 60px;
  @include gradient-vertical(#00539f, #00539f, #0776be, #00539f);
}
.footer #video,
.footer #redes-sociales{
  padding-top: 6px;
}
.footer #div-redes-sociales{
  float: right;
}
.footer #div-redes-sociales img{
  padding-left: 10px;
}
#push{
  height: 100px;
}
.footer #copyrigth {
  padding-top: 20px;
  color: white;
}
.footer #logo {
  padding-top: 10px;
}
.footer .country{
  float: right;
  margin-top: 10px;
}
/*
    |--------------------------------------------------------------------------
    | Bootstarp personalization
    |--------------------------------------------------------------------------
    |
    */
.form-control {
  height: 30px;
  padding: 2px;
}
.btn-primary {
  background-color: #002D56;
}
.btn-primary:hover {
  background-color: #83E3F8;
  color: black;
}
.input-group-btn:last-child > .btn, .input-group-btn:last-child > .btn-group {
  padding-bottom: 3px;
}
/*
    |--------------------------------------------------------------------------
    | Carousel
    |--------------------------------------------------------------------------
    |
    */
#myCarousel {
  width: 1200px;
  margin: 0px auto;
  overflow: hidden;
  height: 210px;
}
.carousel-inner img {
  @include border-radius(15px);
}
.carousel-control.right,
.carousel-control.left {
  background-image: none;
}
@media (min-width: 768px) {
  .carousel-control .glyphicon-chevron-left, .carousel-control .icon-prev {
    margin-left: -70px;
    font-size: 80px;
    margin-top: -55px;
  }
  .carousel-control .glyphicon-chevron-right, .carousel-control .icon-next {
    margin-right: -70px;
    font-size: 80px;
    margin-top: -55px;
  }
}
#slider-close {
  background-image: url("/images/slider_close.png");
  position: relative;
  background-repeat: no-repeat;
  width: 902%;
  height: 45px;
  margin-left: 30px;
}
.carousel-indicators {
  bottom: 15px;
}

.carousel-indicators li {
  @include gradient-radial(#6a6a6a, #afafaf, #c9c9c9);
  border: 1px solid #afafaf;
}
.carousel-indicators .active {
  @include gradient-radial(#2779c8, #6ba8d5, #4296da);
  border: 1px solid #2779c8;
}
.content-container {
  margin-top: 10px;
  padding-bottom: 20px;
  min-height: 200px;
}

/*
    |--------------------------------------------------------------------------
    | Homepage
    |--------------------------------------------------------------------------
    |
    */
.text-header{
  color: #ce0000;
  font-weight: bold;
  padding: 40px;
}
.text-highlighted{
  color: #ce0000;
  font-weight: bold;
}
.text-body{
  font-family: 'Ubuntu', sans-serif;
  font-size: 14px;
  line-height: 20px;
  padding: 40px;
  margin-bottom: 30px;
}
.text-body ul {
  list-style-type: none;
  padding-left: 20px;
}

.text-body li {
  position: relative;
  padding-left: 20px;
}
.text-body li:before {
    font-family: 'FontAwesome';
    content: '\f18e';
    margin:0 5px 0 -15px;
    color: #017dff;
}

/*
    |--------------------------------------------------------------------------
    | Páginas de login
    |--------------------------------------------------------------------------
    |
    */
.social-login{
  padding-top: 15px;
  width: 150px;
}
.container-fluid{
  margin-top: 50px;
  margin-bottom: 50px;
}
.panel-default {
  border-color: #0776BE;
}
.panel-default > .panel-heading {
  background-color: #0776BE;
  color: white;
  font-weight: bold;
}

/*
    |--------------------------------------------------------------------------
    | Para la biblioteca del cliente y el catálogo de productos
    |--------------------------------------------------------------------------
    |
    */
.catalog-container,
.library-container{
  height: auto;
  width: 100%;
  min-height: 500px;
  display: table;
}
.book-container {
  position: relative;
  float: left;
  margin: 20px;
}
.filters-container {
  width: 100%;
  background-color: #0776be;
  padding-top: 3px;
  padding-bottom: 3px;
  margin-top: 10px !important;
  height: 45px;
}
.filters-container .form-group {
  margin: 5px;
}
.filters-container label{
  color: white;
}
.filters-container #subcategory {
  width: 280px;
}
.book-container {
  padding: 10px;
}
.book-container {
  padding: 10px;
}
.book-container:hover {
  background-color: #f1f1f1;
  @include border-radius(10px);

}
.book-image{
  display: inline;
  float: left;
  position: relative;
}
.book-title {
  float: left;
  position: relative;
  width: 180px;
  height:200px;
}
.book-title p{
  font-family: 'Ubuntu', sans-serif;
  font-size: 16px;
  font-weight: bold;
  position: absolute;
  bottom: 45px;
  text-align: left;
}
.book-title .fa{
  margin-left: 10px;
}
.book-materials{
  width: 117px;
  height: 25px;
  padding-top: 3px;
  color: #1b4f7b;
  opacity: 0;
  position: absolute;
  bottom: 35px;
  left: 162px;
  font-size: 20px;
}

/*
    |--------------------------------------------------------------------------
    | Para la páginas de detalles del libro
    |--------------------------------------------------------------------------
    |
    */
.book-description{
  margin-top: 30px !important;
  background-image: url("/images/Backdetalle.png");
  background-position: right center;
  position: relative;
  background-repeat: no-repeat;
  width: 793px;
  height: 296px;
}
.book-description img {
  position: absolute;
  left: 0px;
  top: 5px;
}
.book-description span {
  font-family: 'Ubuntu', sans-serif;
  font-weight: bold;
  position: absolute;
  font-size: 16px;
  text-align: justify;
  left: 280px;
  top: 100px;
  width: 470px;
  height: 130px;
  padding-right: 15px;
}
.book-info {
  width: 500px;
  @include border-radius(15px);
  background-color: #f4f4f4;
  margin-top: 40px !important;
  margin-bottom: 40px !important;
  padding: 0px !important;
  font-family: 'Ubuntu', sans-serif;
  font-size: 16px;
  box-shadow:inset 0px 0px 10px rgba(0,0,0,.2);
  -webkit-box-shadow:inset 0px 0px 10px rgba(0,0,0,.2);
  -moz-box-shadow:inset 0px 0px 10px rgba(0,0,0,.2);
}
.book-info table {
  width: 100%;
}
.book-info td {
  padding: 10px 15px;
}
.book-info .header {
  font-weight: bold;
  text-transform: uppercase;
  text-align: left;
  color: #0055a0;
  border-bottom: 1px solid #00005f;
  border-right: 1px solid #00005f;
  width: 130px;
}
.book-info .last {
  border-bottom: 1px solid transparent !important;
}
.book-info .body {
  text-align: left;
  border-bottom: 1px solid #00005f;
}
.book-info ul {
  list-style-type: none;
  padding-left: 20px;
}
.book-info li {
  color: #847e84;
  position: relative;
  padding-left: 20px;
}
.book-info li span{
  padding-left: 3px;
}
.book-info .inactive:before {
  font-family: 'FontAwesome';
  content: '\f18e';
  margin:0 5px 0 -15px;
  color: #847e84;
}
.book-info .active a:before {
  font-family: 'FontAwesome';
  content: '\f18e';
  margin:0 5px 0 -15px;
  color: #017dff;
}
.material-icon {
  float: left;
  margin: 3px;
  width: 65px;
  height: 65px;
  background-color: #6a6a6a;
  color: white;
  padding: 5px;
  font-weight: bold;
  text-align: center;
  @include border-radius(5px);
}

.material-icon i{
  font-size: 20px;
  padding: 5px;
}
.material-icon div{
  font-size: 12px;
  line-height: 12px;
}
.options-container {
  width: 100%;
  height: 30px;
}
.options-container form {
  float: right;
}

.material-access {
  background-color: #379c68;
  cursor: pointer;
}

.material-access-denied {
  background-color: #ee8477;
  pointer-events: none;
  cursor: default;
}

.material-external-permission {
  background-color: #eef88d;
  color: black;
  cursor: pointer;
}

.material-access:hover {
  background-color: #1b4830;
  color: white;
  text-decoration: none;
}

.material-access-denied:hover {
  background-color: #d30000;
  color: white;
  text-decoration: none;
}
.material-container {
  position: relative;
  width: 100%;
  padding: 30px;
}
embed, object {
  min-width: 800px;
  max-width: 800px;
  min-height: 600px;
}
#pdf-material embed,
#pdf-material object {
  width: 100% !important;
  max-width: 100% !important;
}

/*
    |--------------------------------------------------------------------------
    | Para la página de contacto
    |--------------------------------------------------------------------------
    |
    */
.contact-content {
  padding: 20px;
}

#tree, ul.fancytree-container {
  border: 1px solid transparent;
  outline: 0;
}
#tree {
  margin-bottom: 30px;
}
.back,
.main-content {
  min-height: 630px;
}